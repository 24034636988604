































import { defineComponent, ref, reactive, toRefs } from '@vue/composition-api';
import HelpTooltipDialogLayout from '@/components/tooltips/HelpTooltipDialogLayout.vue';

const state = reactive({
  dialog: false,
  showTooltip: true
});
const helpTooltipDialog = ref<any>();

export function showRecentJobsHelpTooltipDialog() {
  helpTooltipDialog.value?.show();
}

export default defineComponent({
  name: 'HelpTooltipDialogRecentJobs',
  components: { HelpTooltipDialogLayout },
  setup() {
    return {
      ...toRefs(state),
      helpTooltipDialog
    };
  }
});
