var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("help-tooltip-dialog-layout", {
    key: "help",
    ref: "helpTooltipDialog",
    attrs: { title: "My\nDomain Workspaces" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("v-card-text", [
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  " All Domain Workspaces are listed on this page. You can create a new Domain Workspace by adding a Domain. Once it is added, you can begin performing operations on that Domain. "
                )
              ]),
              _c("p", [
                _vm._v(
                  ' To get started, click "Add Domain" and configure your Domain with appropriate Five9 Admin credentials for your Domain. Once you have added a Domain, these actions will appear. '
                )
              ]),
              _c(
                "p",
                [
                  _c("a", [_vm._v("Label (link)")]),
                  _c("v-icon", { staticClass: "mx-3" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " Click the Domain's label to go to that Domain's workspace. "
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-pencil")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(" Edit this Domain. ")
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-key")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(" Edit the credentials for this Domain. ")
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-information-outline")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " Show information for this Domain. This icon is also available within the Domain's Workspace in the top toolbar. "
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }