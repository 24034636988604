





































import { defineComponent, reactive, toRefs, ref } from '@vue/composition-api';
import HelpTooltipDialogLayout from '@/components/tooltips/HelpTooltipDialogLayout.vue';

const helpTooltipDialog = ref<any>();

const state = reactive({
  dialog: false
});

export function showDomainListHelpTooltipDialog() {
  return helpTooltipDialog.value?.show();
}

export default defineComponent({
  name: 'HelpTooltipDialogDomainList',
  components: { HelpTooltipDialogLayout },
  setup() {
    return {
      ...toRefs(state),
      helpTooltipDialog
    };
  }
});
