var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("help-tooltip-dialog-layout", {
    key: "recentjobs",
    ref: "helpTooltipDialog",
    attrs: { title: "Recent Jobs" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("v-card-text", [
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  " Recent Jobs show any Job type that has been initiated within this Domain's workspace. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Once you've created a Job, it will show up in the table below, with these available actions depending on the job status. "
                )
              ]),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-wizard-hat")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " Setup needs to be finished for the Job. Click to continue setting up the Job through a wizard. "
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-eye")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " The Job has been set up and is ready to be run. Click to go to the Job detail page where it can be run. "
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-magnify")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " Browse all Domain operations. This is an advanced search feature to view the Job's operations. "
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("v-icon", [_vm._v("mdi-file-excel")]),
                  _c("v-icon", { staticClass: "mx-6" }, [
                    _vm._v("mdi-arrow-right")
                  ]),
                  _vm._v(
                    " Open the report menu to download a report for this Job. "
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }