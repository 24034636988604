var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "absolute", display: "none" } },
    [
      _c("help-tooltip-dialog-domain-list"),
      _c("help-tooltip-dialog-recent-jobs")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }