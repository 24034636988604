






import { defineComponent } from '@vue/composition-api';
import HelpTooltipDialogDomainList, {
  showDomainListHelpTooltipDialog
} from '@/components/tooltips/HelpTooltipDialogDomainList.vue';
import HelpTooltipDialogRecentJobs, {
  showRecentJobsHelpTooltipDialog
} from '@/components/tooltips/HelpTooltipDialogRecentJobs.vue';

export function useHelpTooltipDialogs() {
  return {
    showDomainListHelpTooltipDialog,
    showRecentJobsHelpTooltipDialog
  };
}

export default defineComponent({
  name: 'HelpTooltipDialogs',
  components: { HelpTooltipDialogDomainList, HelpTooltipDialogRecentJobs },
  setup() {
    return {};
  }
});
